import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

// Utility function to generate table headers
const generateTableHeaders = (columns) => {
  return columns
    ?.map((col) => col.title)
    ?.filter((header) => header !== "Actions" && header !== "Sl No");
};

// Utility function to generate table body rows
const generateTableBody = (data, tableHeaders, fieldMapping) => {
  return data?.map((item) => {
    return tableHeaders?.map((header) => {
      const field = fieldMapping[header];
      let value = field?.includes(".")
        ? field.split(".").reduce((obj, key) => obj && obj[key], item)
        : item[field];

      if (field === "transactionDate") {
        value = dayjs(value).format("DD-MM-YY"); // Format the date
      }
      if (field === "billDate") {
        value = dayjs(value).format("DD-MM-YY"); // Format the date
      }
      if (field === "editDate") {
        value = dayjs(value).format("DD-MM-YY"); // Format the date
      }
      if (field === "date") {
        value = dayjs(value).format("DD-MM-YY"); // Format the date
      }
      if (field === "entryDate") {
        value = dayjs(value).format("DD-MM-YY"); // Format the date
      }
      if (field === "section") {
        value = item?.section?.name; // Get product name
      }
      if (field === "counter") {
        value = item?.counter?.name; // Get unit name
      }
      if (field === "category") {
        value = item?.category?.name; // Get unit name
      }
      if (field === "type") {
        value = item?.type?.name; // Get unit name
      }
      if (field === "paymentType") {
        value =
          item?.paymentType === 0
            ? "Cash"
            : item?.paymentType === 1
            ? "Card"
            : item?.paymentType === 2
            ? "Bank"
            : item?.paymentType === 3
            ? "Credit"
            : item?.paymentType === 4
            ? "UPI"
            : "None";
      }
      if (field === "paymenCardType") {
        value = item?.paymenCardType?.cardName; // Get unit name
      }
      if (field === "user") {
        value = item?.user?.name; // Get unit name
      }
      if (field === "salesStatus") {
        value =
          item?.salesStatus === 0
            ? "completed"
            : item?.salesStatus === 1
            ? "pending"
            : "Unknown";
      }
      if (field === "branch") {
        value = item?.branch?.name;
      }
      if (field === "product") {
        value = item?.product?.name;
      }
      if (field === "customer") {
        value = item?.customer?.name; // Get unit name
      }
      if (field === "ledgerName") {
        value = item?.ledgerName?.name;
      }
      return value;
    });
  });
};

// PDF Export
export const exportreportPDF = (getActiveTabData, name) => {
  const { data, columns, fieldMapping} = getActiveTabData();
  console.log(data,"pdffffffffffffffff");
  console.log("columns", columns);
  console.log("fieldMapping", fieldMapping);
  // Set orientation based on the number of columns (e.g., more than 5 columns -> landscape)
  const orientation = columns?.length > 9 ? "landscape" : "portrait";

  // Initialize jsPDF with the dynamic orientation
  const doc = new jsPDF({
    orientation: orientation,
  });
  const branchName = data?.[1].branch?.name || "Branch Name Not Available";
 

  // Add title and branch name to the PDF
  doc.setFontSize(16);
  doc.text(`${name}`, 14, 20); // Report title
  doc.setFontSize(12);
  doc.text(`Branch: ${branchName}`, 14, 30); // Branch name

  // Generate table headers and body
  const tableHeaders = generateTableHeaders(columns);
  const tableBody = generateTableBody(data, tableHeaders, fieldMapping);

  // Use autoTable to export the table
  // autoTable(doc, {
  //   head: [tableHeaders],
  //   body: tableBody,
  // });
  autoTable(doc, {
    head: [tableHeaders],
    body: tableBody,
    theme: "grid",
    styles: { fontSize: 10, cellPadding: 3 },
    headStyles: { fillColor: [63, 81, 181] }, // Indigo
    startY: 40, // Start below the branch name
  });

  // Save the PDF
  doc.save(`${name}.pdf`);
  
};

// Excel Export
// export const exportreportExcel = (getActiveTabData, name) => {
//   const { data, columns, fieldMapping } = getActiveTabData();
//   const tableHeaders = generateTableHeaders(columns);
//   const tableBody = Array.isArray(data)
//     ? generateTableBody(data, tableHeaders, fieldMapping)
//     : [];
//   console.log("tableHeaders", tableHeaders);
//   console.log("tableBody", tableBody);
//   if (!Array.isArray(tableBody)) {
//     console.error("Error: Table Body is not an array.");
//     return;
//   }
//   const worksheet = XLSX.utils.aoa_to_sheet([tableHeaders, ...tableBody]);
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
//   XLSX.writeFile(workbook, `${name}.xlsx `);
// };

/////////////////
export const exportreportExcel = (getActiveTabData, name) => {
  const { data, columns, fieldMapping } = getActiveTabData();

  // Generate table headers and body
  const tableHeaders = generateTableHeaders(columns);
  const tableBody = Array.isArray(data)
    ? generateTableBody(data, tableHeaders, fieldMapping)
    : [];

  console.log("Headers:", tableHeaders);
  console.log("Body:", tableBody);

  if (!Array.isArray(tableBody)) {
    console.error("Error: Table Body is not an array.");
    return;
  }

  // Clean up data
  const sanitizedBody = tableBody.map(
    (row) => row.map((cell) => (cell == null ? "" : cell)) // Replace null/undefined with empty string
  );

  try {
    const worksheet = XLSX.utils.aoa_to_sheet([tableHeaders, ...sanitizedBody]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    XLSX.writeFile(workbook, `${name}.xlsx`);
  } catch (error) {
    console.error("Error generating Excel file:", error);
  }
};

////////////////

// Printable format
export const printreportReport = (getActiveTabData, name) => {
  const { data, columns, fieldMapping } = getActiveTabData();
  const printWindow = window.open("", "_blank");

  // Prepare the document for printing
  printWindow.document.write("<html><head><title>Print Report</title>");
  printWindow.document.write("<style>");
  printWindow.document.write(`
        table {
            width: 100%;
            border-collapse: collapse;
        }
        th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
        }
        th {
            background-color: #f2f2f2;
        }
    `);
  printWindow.document.write("</style></head><body>");
  printWindow.document.write(`<h1>${name}</h1>`);

  const tableHeaders = generateTableHeaders(columns);
  let tableHTML = "<table><thead><tr>";
  tableHeaders.forEach((header) => {
    tableHTML += `<th>${header}</th>`;
  });
  tableHTML += "</tr></thead><tbody>";

  const tableBody = generateTableBody(data, tableHeaders, fieldMapping);
  tableBody.forEach((row) => {
    tableHTML += "<tr>";
    row.forEach((cell) => {
      tableHTML += `<td>${cell}</td>`;
    });
    tableHTML += "</tr>";
  });
  tableHTML += "</tbody></table>";

  printWindow.document.write(tableHTML);
  printWindow.document.write("</body></html>");

  printWindow.document.close();
  printWindow.print();
};
